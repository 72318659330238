<template>
    <div>
         <v-card>
        <v-row>
            <v-col>
                 
                <v-btn
                    color="primary"
                    x-small
                    class="mr-3 mt-1"
                    elevation="5"
                    @click="sendMessagge()"
                    fab>
                    <v-icon height="26" style="font-size: 16px !important"
                        >fas fa-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
    </div>
   
</template>

<script>
    export default {
        
        props: {},
        data: () => ({

        }),
        methods:{
            sendMessagge(){

                let _data = {
                    "messaging_product": "whatsapp",    
                    "recipient_type": "individual",
                    "to": "51910768149",
                    "type": "text",
                    "text": {
                        "preview_url": false,
                        "body": "Hola Sr(a) ha sido seleccionado para trabajar en la planta SUNSHINE"
                    }
                }

                let _header = new Headers();
                _header.append('Authorization', 'Bearer EAAKD5ojSEo8BOwsc4ZBzs8Ezper88qaIgA3ZAzgApTtiebi9kZAB0kQlQZC9DCftZAZBMZB8qtaQEUeR5dbFFFXwb4HzP9HwEj2BS4c9c4T72rcv6h5RLnToORb8DcFPF4M972ZBVJrW0P06ZB2AM1t3t45B8CjZBdfxrQUCjpcMZATE6y4HWRgiFRZCBmUx0taYRBObiQJhD6vXNvozTkt5NZCsZD');
                _header.append('Content-Type', 'application/json');
                // _header.append('Content-Length', '<calculated when request is sent>');
                // _header.append('Host', '<calculated when request is sent>');
                _header.append('User-Agent', 'PostmanRuntime/7.35.0');
                _header.append('Accept', '*/*');
                _header.append('Accept-Encoding', 'gzip, deflate, br');
                _header.append('Connection', 'keep-alive');
                _header.append('Access-Control-Allow-Origin', '*');
                

               

                var miInicializador = { method: 'POST',
                                        headers: _header,
                                        body: JSON.stringify(_data),
                                        mode: 'cors',
                                        cache: 'default' };

                var miSolicitud = new Request('https://graph.facebook.com/v17.0/185911951261674/messages');

                fetch(miSolicitud, miInicializador).then(function(respuesta) {
                    console.log(respuesta)
                });
            }
        },
        mounted(){

        }
    }
</script>